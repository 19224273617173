import {createUseStyles} from 'react-jss';
import {Open_Sans} from 'next/font/google';

export const openSans = Open_Sans({subsets: ['latin-ext'], display: 'swap', fallback: ['sans-serif']});

export const useGlobalStyles = createUseStyles((theme) => ({
  '@global': {
    html: {
      fontSize: '16px',
      backgroundColor: 'white',
      // eslint-disable-next-line @typescript-eslint/quotes
      fontFamily: `${openSans.style.fontFamily.replaceAll("'", '')} !important`,
      fontStyle: `${openSans.style.fontStyle} !important`,
    },
    body: {
      fontSize: 'inherit',
      lineHeight: '1.625rem',
      margin: 0,
    },
    main: {
      maxWidth: '1100px',
      margin: '0 auto 3rem',
      padding: '0 1rem',

      '@media print': {
        marginTop: 0,
        padding: 0
      },
      '& *': {
        fontSize: '1em',
        // eslint-disable-next-line @typescript-eslint/quotes
        fontFamily: `${openSans.style.fontFamily.replaceAll("'", '')} !important`,
        fontStyle: `${openSans.style.fontStyle} !important`,
      },
      '& p': {
        fontSize: '1rem'
      },

      '& a': {
        color: theme.colors.link.normal,
        '&:visited': {
          color: theme.colors.link.visited,
        },
        '&:hover': {
          color: theme.colors.link.hover.fg,
          backgroundColor: theme.colors.link.hover.bg,
          fontWeight: 'normal',
        },
        '&:focus': {
          color: theme.colors.link.focus.fg,
          backgroundColor: theme.colors.link.focus.bg,
          textDecoration: `underline 3px solid ${theme.colors.link.focus.fg}`,
          outline: 'none',
        },
        '&:active': {
          color: theme.colors.link.active.fg,
          backgroundColor: theme.colors.link.active.bg,
          textDecoration: `underline 3px solid ${theme.colors.link.active.fg}`,
        },
        '@media print': {
          '&, &:visited': {
            color: 'inherit',
            textDecoration: 'inherit',
          }
        },
      },
      '& address': {
        fontStyle: 'inherit'
      },
    },
    '.sr-only': {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: 0,
      margin: '-1px',
      overflow: 'hidden',
      clip: 'rect(0,0,0,0)',
      border: 0
    },
    '@media screen': {
      '.print-only': {
        display: 'none'
      },
    },
    '@media print': {
      '.no-print': {
        display: 'none !important'
      },
      header: {
        '&.main-header': {
          background: 'none !important'
        },
        '& div.header-right': {
          display: 'none'
        }
      },
      'footer.main-footer': {
        display: 'none'
      },
      'div.operation-alert': {
        display: 'none',
      }
    }
  }
}));
