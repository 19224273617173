'use client';

import {ConceptID} from 'features/terms/felles-data-katalog/concept-ids';
import {createContext, FC, ReactNode, useEffect, useState} from 'react';
import {ConceptDefinition} from './felles-data-katalog/model';
import {useLang} from 'features/i18n/use-lang';


interface TermContextProps {
  descriptions: {
    visible: boolean;
    setVisibility: (v: boolean) => void;
  };
  conceptDefinitions: Partial<Record<ConceptID, ConceptDefinition>>;
}

const initialState: TermContextProps = {
  descriptions: {
    visible: false,
    setVisibility: () => {}
  },
  conceptDefinitions: {}
};

export const termDescriptionContext = createContext(initialState);

export const TermDescriptionProvider: FC<{
  children: ReactNode;
  conceptDefinitions?: Partial<Record<ConceptID, ConceptDefinition>>;
}> = ({children, conceptDefinitions}) => {
  const [visible, setVisibility] = useState<boolean>(false);
  const lang = useLang();

  useEffect(() => {
    setVisibility(JSON.parse(window.sessionStorage.getItem('show-term-descriptions') ?? 'false'));
  }, []);


  return (
    <termDescriptionContext.Provider value={{
      descriptions: {
        visible: visible && lang !== 'en',
        setVisibility: (v: boolean) => {
          window.sessionStorage.setItem('show-term-descriptions', JSON.stringify(v));
          setVisibility(v);
        }
      },
      conceptDefinitions: conceptDefinitions ?? {}
    }}>{children}</termDescriptionContext.Provider>
  );
};
