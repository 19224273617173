import {StringUtils} from 'common/utils/string-utils';


export class Handlers {

  static onCopy(event: ClipboardEvent) {
    const selectedPart = window.getSelection();

    if (selectedPart) {
      const selectedText = selectedPart.toString()
        .replaceAll('\u200c ', '')
        .replaceAll(/[ \-]/g, '');
      if (StringUtils.isNumber(selectedText)) {
        event?.clipboardData?.setData('text/plain', selectedText);
        event.preventDefault();
      }
    }
  }
}
