import {getEnv} from 'environment/public.environment';
import {InitOptions} from 'i18next';

export const getI18NOptions = (): Partial<InitOptions> => ({
  supportedLngs: getEnv().language.supported,
  fallbackLng: getEnv().language.default,
  interpolation: {
    skipOnVariables: false,
    escapeValue: false,
    format: (value, format, lang): string => {
      if (format === 'possessive') {
        if (lang === 'nb' || lang === 'nn') {
          value = value || '';
          const postfix = ['z', 's'].includes(value[value.length - 1]) ? '&39;' : 's';
          return value + postfix;
        } else if (lang === 'en') {
          return `${value}'s`;
        }
      }
      return value;
    }
  },
});
