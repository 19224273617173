'use client';

import {Modal} from 'common/components/modal/modal';
import {useTranslation} from 'react-i18next';
import {Button} from 'common/components/button';
import {signIn, useSession} from 'next-auth/react';
import {useEffect, useState} from 'react';

export const RemoteSignoutModal = () => {
  const {t} = useTranslation('common');
  const [open, setOpen] = useState<boolean>(false);
  const {data} = useSession({required: false});

  useEffect(() => {
    if (data?.error && ['AccessTokenRevokedError', 'RefreshAccessTokenError'].includes(data?.error)) {
      setOpen(true);
    };
  }, [data?.error]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={onClose} title={t('common:external-signout.title')}>
      <p>{t('common:external-signout.info')}</p>
      <div>
        <Button variant='primary'
                data-transaction-name='external-signout-sign-back-in'
                data-testid='pt-signin'
                onClick={() => signIn('idporten')}>
          {t('common:external-signout.login-btn')}
        </Button>
        <Button variant='secondary'
                data-transaction-name='external-signout-stay-signed-out'
                data-testid='pt-close'
                onClick={onClose}>
          {t('common:external-signout.close-btn')}
        </Button>
      </div>
    </Modal>
  );
};
