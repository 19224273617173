'use client';
import {Modal} from 'common/components/modal/modal';
import {useTranslation} from 'react-i18next';
import {useRouter, useSearchParams} from 'next/navigation';
import {Button} from 'common/components/button';
import {signIn} from 'next-auth/react';
import {useEffect, useState} from 'react';
import {SignoutReason} from 'features/auth/signout-reason';

export const PostTimeoutModal = () => {
  const {t} = useTranslation(['common']);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const queryParams = useSearchParams();

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (queryParams?.get('signoutReason') === SignoutReason.INACTIVE) {
      const url = new URL(window.location.href);
      url.searchParams.delete('signoutReason');
      router.replace(url.toString());
      setOpen(true);
    }
  }, []);

  return (
    <Modal open={open} onClose={onClose} title={t('common:post-timeout.title')}>
      <p>{t('common:post-timeout.info')}</p>
      <div>
        <Button variant='primary'
                data-transaction-name='post-timeout-sign-back-in'
                data-testid='pt-signin'
                onClick={() => signIn('idporten')}>
          {t('common:post-timeout.login-btn')}
        </Button>
        <Button variant='secondary'
                data-transaction-name='post-timeout-stay-signed-out'
                data-testid='pt-close'
                onClick={onClose}>
          {t('common:post-timeout.close-btn')}
        </Button>
      </div>
    </Modal>
  );
};
