'use client';

import React, {FC, ReactNode, useState} from 'react';
import {useServerInsertedHTML} from 'next/navigation';
import {JssProvider, SheetsRegistry, ThemeProvider} from 'react-jss';
import {CreateGenerateId, Rule, StyleSheet} from 'jss';
import {useGlobalStyles} from 'styles/global';
import {theme} from 'styles/theme';
import {StringUtils} from 'common/utils/string-utils';

export const GlobalStyles: FC = () => {
  useGlobalStyles();
  return null;
};

export const JssRegistry: FC<{children: ReactNode; nonce: string}> = ({children, nonce}) => {
  const [registry] = useState(new SheetsRegistry());

  useServerInsertedHTML(() => {
    return <style id='jss-styles' nonce={nonce}>{registry.toString().replaceAll('\n', ' ')}</style>;
  });

  const createGenId: CreateGenerateId = () => {
    return (rule: Rule, sheet?: StyleSheet<string>) => {
      const prefix = sheet?.options.classNamePrefix;
      // @ts-expect-error
      const jssId = sheet.options.jss.id;

      return `${prefix}${rule.key}-${jssId}_${StringUtils.simpleHash(sheet?.toString() ?? '')}`;
    };
  };
  return (
    <JssProvider registry={registry} generateId={createGenId()}>
      <ThemeProvider theme={theme}>
        <GlobalStyles/>
        {children}
      </ThemeProvider>
    </JssProvider>
  );
};
