import {SignoutReason} from 'features/auth/signout-reason';
import {usePathname} from 'next/navigation';

export const useEndSession = () => {
  const pathname = usePathname();

  return (reason?: SignoutReason) => {
    const query = new URLSearchParams({postLogoutUrl: pathname ?? ''});
    if (reason && reason !== SignoutReason.DEFAULT) {
      query.set('signoutReason', reason);
    }

    window.location.assign(`/api/logout?${query.toString()}`);
  };
};
