import {createUseStyles} from 'react-jss';
import {EventHandler, FC, HTMLAttributes, ReactNode, SyntheticEvent} from 'react';
import {Modal as MuiModal} from '@mui/base/Modal';
import {Icon} from 'common/components/icon';
import {XMarkIcon} from '@navikt/aksel-icons';

const useModalStyles = createUseStyles((theme) => ({
  modal: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(1, 1, 1, 0.5)',
    zIndex: 1000,
  },
  container: {
    border: 'none',
    boxShadow: theme.shadow.card,
    background: 'white',
    maxWidth: '500px',
    padding: '2rem 2rem 1.5rem',
    zIndex: 2000,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '1rem',
    fontWeight: '600',
    margin: 0
  },
  close: {
    border: 'none',
    background: 'none',
    color: theme.colors.primary,
    fontSize: '1rem'
  }
}), {name: 'Modal'});

const BackdropUnstyled: FC<HTMLAttributes<HTMLDivElement> & {open: string}> = ({open, ...backdropProps}) => (
  <div data-testid='modal-backdrop' {...backdropProps}
       className={`${backdropProps.className} ${open ? 'MuiBackdrop-open' : ''}`}/>
);

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: EventHandler<SyntheticEvent<unknown>>;
  title: string;
}

export const Modal: FC<ModalProps> = ({open, onClose, title, children}) => {
  const styles = useModalStyles();

  return (
    <MuiModal open={open}
              data-testid="modal"
              className={styles.modal}
              onClose={onClose}
              slots={{
                backdrop: BackdropUnstyled
              }}
              slotProps={{
                backdrop: {className: styles.backdrop}
              }}>
      <div className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          <button data-testid='close-btn' className={styles.close} onClick={onClose}><Icon icon={XMarkIcon}/></button>
        </header>
        {children}
      </div>
    </MuiModal>
  );
};
