export namespace TimeUtil {
  export const minuteToMs = (minutes: number) => {
    return minutes * 60_000;
  };


  export const msToMinute = (ms: number) => {
    return Math.floor(ms / 60_000);
  };

  export const msToSeconds = (ms: number) => {
    return Math.floor(ms / 1000);
  };

  export const msToMinutesAndSeconds = (ms: number) => {
    return {
      minutes: msToMinute(ms),
      seconds: msToSeconds(ms % 60_000)
    };
  };
}
