import {colorPalette} from 'styles/colors';

export const theme = {
  colors: {
    primary: colorPalette.mainBlue70,
    secondary: colorPalette.secondaryBlue80,
    accent: colorPalette.grey05,
    danger: {
      fg: colorPalette.red90,
      bg: colorPalette.red05
    },
    warn: colorPalette.yellow025,
    info: {
      fg: colorPalette.orange50,
      bg: colorPalette.orange10
    },
    hover: colorPalette.mainBlue05,
    accentHover: colorPalette.secondaryBlue10,
    focus: colorPalette.yellow20,
    active: colorPalette.mainBlue05,
    text: {
      heading: colorPalette.pureGrey80,
      p: colorPalette.grey90,
    },
    link: {
      normal: colorPalette.mainBlue70,
      visited: colorPalette.purple70,
      hover: {
        fg: colorPalette.secondaryBlue80,
        bg: colorPalette.mainBlue05
      },
      focus: {
        fg: colorPalette.secondaryBlue80,
        bg: colorPalette.yellow20
      },
      active: {
        fg: colorPalette.secondaryBlue80,
        bg: colorPalette.secondaryBlue05
      },
    },
    separator: colorPalette.pureGrey20
  },
  shadow: {
    btnPrimary: '1px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    btnHover: '2px 4px 8px 0px rgba(0, 0, 0, 0.1)',
    btnFocus: '2px 4px 8px 0px rgba(0, 0, 0, 0.1)',
    btnActive: '2px 4px 8px 0px rgba(0, 0, 0, 0.2)',
    light: '1px 2px 4px rgba(0, 0, 0, 0.1)',
    light2: '4px 8px 16px rgba(0, 0, 0, 0.1)',
    card: '1px 4px 4px 0px rgba(0, 0, 0, 0.1)'
  },
  borderRadius: '4px',
  colorPalette: colorPalette
};

export type Theme = typeof theme;
